<template>
  <v-autocomplete
    v-model="model"
    :items="items"
    item-value="id"
    :search-input.sync="search"
    :label="isMultiple ? $tc('studies.selectStudy.label', 2) : $tc('studies.selectStudy.label', 1)"
    :multiple="isMultiple"
    :return-object="returnObject"
    :disabled="hasDisabledInput"
    :clearable="isClearable"
    outlined
    hide-details
    :rules="isRequired ? requiredRules : []"
    :validate-on-blur="isRequired"
    @input="onInput">
    <template v-slot:selection="{ attr, on, item, selected }">
      <span v-if="item.fm_referent">
        {{ item.fm_referent.firstname }} {{ item.fm_referent.name }}
      </span>
      <span> - {{ item.state }}</span>
    </template>
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <span v-if="item.fm_referent">
          {{ item.fm_referent.firstname }} {{ item.fm_referent.name }}
        </span>
        - {{ item.state }}
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import formRules from "@/mixins/formRules";

export default {
  name: "SelectStudy",

  mixins: [formRules],

  props: {
    isRequired: {type: Boolean, default: false},
    isClearable: {type: Boolean, default: true},
    isMultiple: {type: Boolean, default: false},
    hasDisabledInput: {type: Boolean, default: false},
    accountId: {type: String, default: null},
    productId: {type: String, default: null},
    value: {type: String|Object, default: null},
    returnObject: {type: Boolean, default: true}
  },

  components: {
  },

  data() {
    return {
      items: [],
      isLoading: false,
      model: null,
      search: null,
      timeId: null,
      timerCount: 500
    }
  },

  created() {
    this.getItems();
  },

  methods: {
    getItems() {
      this.isLoading = true;
      this.$http
        .get(`/studies?accounts[]=${this.accountId}&products[]=${this.productId}&search=${this.search}`, {
          headers: {Authorization: "Bearer " + this.$session.get('jwt')}
        })
        .then(res => {
          this.items = res.data.data;
          this.model = this.value;

          if (this.model) {
            // is Array
            if (Array.isArray(this.model)) {
              this.items.push(
                ...this.model.filter((el) => this.items.findIndex(el2 => el2.id === el.id) === -1));
            }
            else {
              if (this.items.findIndex(el2 => el2.id === this.model.id) === -1)
                this.items.push(this.model)
            }
          }
        })
        .catch((err) => {

        })
        .finally(() => {
          this.isLoading = false;
        })
    },

    onInput(val) {
      this.$emit("input", val);
      this.search = "";
    }
  },
  watch: {
    search(val) {
      const modelStr = this.model && typeof this.model === "object" && this.model["name"] ? this.model.name : this.model;
      if (val && val !== modelStr && !this.isLoading) {
        this.isLoading = true;
        this.timerId = setTimeout(() => {
          this.getItems();
        }, this.timerCount);
      }
    },

    accountId() {
      this.getItems();
    },

    productId() {
      this.getItems();
    }
  }
}
</script>

<style scoped>

</style>
